<!-- eslint-disable prettier/prettier -->
<template>
    <loading :active="load" :can-cancel="false" :is-full-page="true" color="#11592e"></loading>

    <div v-if="!load" class="month-card-container">
        <CardComponent v-if="getSelectedMonth(props.datePeriod) === (currentDate.getMonth() + 1)">
            <div class="month-container">
                <h2 class="month-title">{{ getMonth("Full", currentDate.getMonth() + 1) }} <span class="year-title">{{
                    currentDate.getFullYear() }}</span>
                </h2>
                <img src="@/assets/targets/fi-rr-pencil.svg" alt="pencil-icon" @click="openEditTarget()">
            </div>

            <ModalTargetCurrentMonth :valueCurrent="Math.trunc(targetsData.registrationCurrent)" title="Cadastros"
                :targetCurrent="Math.trunc(targetsData.registrationTarget)"
                :superTargetCurrent="Math.trunc(targetsData.registrationSuperTarget)" urlImage="add-user-icon" />

            <ModalTargetCurrentMonth :valueCurrent="targetsData.revenueCurrent" title="Receita (R$)"
                :targetCurrent="targetsData.revenueTarget" :superTargetCurrent="targetsData.revenueSuperTarget"
                urlImage="amount-icon" />

            <ModalTargetCurrentMonth title="Abastecimentos" :valueCurrent="Math.trunc(targetsData.fuelingCurrent)"
                :targetCurrent="Math.trunc(targetsData.fuelingTarget)"
                :superTargetCurrent="Math.trunc(targetsData.fuelingSuperTarget)" urlImage="fuelTransactions-icon" />

            <ModalTargetCurrentMonth title="Galonagem (L)" :valueCurrent="targetsData.gallonageCurrent"
                :targetCurrent="targetsData.gallonageTarget" :superTargetCurrent="targetsData.gallonageSuperTarget"
                urlImage="gallonage-icon" />
        </CardComponent>

        <CardComponent v-else>
            <div class="month-container">
                <h2 class="month-title">{{ getMonth("Full", getMonthOfDate(targetsData.createdAt)) }} <span
                        class="year-title">{{
                            getYearOfDate(targetsData.createdAt) }}</span>
                </h2>
            </div>

            <ModalTargetContent :valueCurrent="Math.trunc(targetsData.registrationCurrent)" title="Cadastros"
                :target="Math.trunc(targetsData.registrationTarget)"
                :superTarget="Math.trunc(targetsData.registrationSuperTarget)" urlImage="add-user-icon" />

            <ModalTargetContent :valueCurrent="targetsData.revenueCurrent" title="Receita (R$)"
                :target="targetsData.revenueTarget" :superTarget="targetsData.revenueSuperTarget"
                urlImage="amount-icon" />

            <ModalTargetContent title="Abastecimentos" :valueCurrent="Math.trunc(targetsData.fuelingCurrent)"
                :target="Math.trunc(targetsData.fuelingTarget)"
                :superTarget="Math.trunc(targetsData.fuelingSuperTarget)" urlImage="fuelTransactions-icon" />

            <ModalTargetContent title="Galonagem (L)" :valueCurrent="targetsData.gallonageCurrent"
                :target="targetsData.gallonageTarget" :superTarget="targetsData.gallonageSuperTarget"
                urlImage="gallonage-icon" />
        </CardComponent>

    </div>


    <ModalEditTargets :gasStationId="props.gasStationId" v-if="isOpenModalEdit" @cancel-modal="isOpenModalEdit = false"
        :update-cards="getDataCurrentMonth" />

</template>


<script setup>
import {
    ref,
    onMounted,
    defineProps,
    watch, defineAsyncComponent
} from "vue";

import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import ModalEditTargets from "../ModalEditTargets/ModalEditTargets.vue";
import { getMonthOfDate, getYearOfDate } from "./utils/formatDate";
import { getMonth } from "@/mixins";
import Loading from "vue3-loading-overlay";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import ModalTargetCurrentMonth from "./ModalTargetCurrentMonth.vue";
import ModalTargetContent from "./ModalTargetContent.vue";

const currentDate = new Date();
const gasStationController = new GasStationController();
const load = ref(true);
const props = defineProps({
    managerId: {
        type: Number,
        required: true,
    },
    datePeriod: {
        type: String,
        required: true,
    },
    gasStationId: {
        type: Number,
        required: true,
    }
});

const isOpenModalEdit = ref(false)
const targetsData = ref([])


const openEditTarget = () => {
    isOpenModalEdit.value = true
}

const getDataCurrentMonth = async () => {
    load.value = true
    await gasStationController.getTargetLastMonth(props.gasStationId)
        .then((response) => {
            targetsData.value = response.data
        })
        .catch((error) => {
            console.log(error)
        })
        .finally(() => {
            load.value = false;
        });
}

const getSelectedMonth = (dateSelected) => {
    let monthFormated;
    monthFormated = parseInt(dateSelected.split('-')[1])

    return monthFormated
}

const getSelectedYear = (dateSelected) => {
    let monthFormated;
    monthFormated = (dateSelected.split('-')[0])

    return monthFormated
}

const getOuthersMonths = async () => {
    load.value = true
    await gasStationController.getTargetMonth(props.gasStationId, props.datePeriod)
        .then((response) => {
            targetsData.value = response.data[0]
        })
        .catch((error => {
            console.log(error)
        }))
        .finally(() => {
            load.value = false;
        });
}



const getData = async () => {
    if (!props.datePeriod || (getSelectedMonth(props.datePeriod) <= 4 &&
        getSelectedYear(props.datePeriod) <= 2024) || getSelectedYear(props.datePeriod) > currentDate.getFullYear()) {
        window.location.reload()
        return
    } else if (getSelectedMonth(props.datePeriod) > currentDate.getMonth() + 1) {
        window.location.reload()
        return
    } else {
        if (parseInt(props.datePeriod.split('-')[1]) !== (currentDate.getMonth() + 1)) {
            getOuthersMonths();
        } else {
            getDataCurrentMonth();
        }
    }
};

watch(props.gasStationId, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        getData()

    }
});

watch(() => props.datePeriod, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        getData()
    }
});


onMounted(() => {
    getData()
});


</script>


<style lang="scss" scoped>
@import "./TargetsListMonth.scss";
</style>
